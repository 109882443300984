import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Session from '../helpers/Session';
import Loading from './Load';

const Calendly = () => {
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingWidget, setIsLoadingWidget] = useState(true);

  useEffect(() => {
    !isLoadingWidget &&
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/d/cpmm-thj-jrf/apresentacao-kobana?background_color=151515&text_color=ffffff&primary_color=d3fc55',
        parentElement: document.getElementById('calendly'),
        utm: {
          utmCampaign: cookies['utm_campaign'],
          utmSource: cookies['utm_source'],
          utmMedium: cookies['utm_medium'],
          utmContent: cookies['utm_comntent'],
          utmTerm: cookies['utm_term'],
        },
        prefill: {
          firstName: Session.getItem('firstName'),
          lastName: Session.getItem('lastName'),
          email: Session.getItem('email'),
          customAnswers: {
            a1: '+55' + Session.getItem('ddd') + Session.getItem('phone'),
            a2: Session.getItem('company'),
            a3: obsText(),
          },
        },
      });

    setTimeout(() => {
      const calendlyDiv = document.getElementById('calendly');
      if (calendlyDiv) {
        setIsLoadingWidget(false);
      }
      isLoadingWidget && setIsLoading(false);
    }, 500);
  });

  const obsText = () => {
    let segmentText =
      Session.getItem('segment') === 'Outro'
        ? Session.getItem('otherSegment')
        : Session.getItem('segment');
    let goalText =
      Session.getItem('goal') === 'Outro'
        ? Session.getItem('otherGoal')
        : Session.getItem('goal');
    return 'Empresa do segmento ' + segmentText + ' querendo ' + goalText + '.';
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div id="calendly" style={{ minWidth: '100%', height: '800px' }} />
      )}
    </>
  );
};

export default Calendly;
