import React from 'react';
import Session from '../helpers/Session';

const Agradecimento = (props) => {
  return (
    <div className="internal-space final-page">
      <h1>Obrigado pelo seu interesse na Kobana!</h1>
      <p>{Session.getItem('firstName')},</p>
      <p>Agradecemos por preencher suas informações. Identificamos que o volume de operações informado está abaixo do que geralmente trabalhamos para garantir a melhor experiência com nossa solução.</p>
      <p>Caso ainda tenha interesse ou sua demanda cresça no futuro, ficaremos felizes em conversar e entender melhor suas necessidades. Você pode entrar em contato conosco pelo <a href="https://l.kobana.com.br/whatsapp">WhatsApp</a>.</p>
      <p>● A equipe Kobana</p>
    </div>
  )
}

export default Agradecimento;
