import React, { useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Session from '../helpers/Session';
import PageHeader from '../components/PageHeader';
import PrimaryButton from '../components/PrimaryButton';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import TagInput from '../components/TagInput';

const ranges = [
  ['Menos de 1.000', '1000'],
  ['1.000 a 5.000', '1000-5000'],
  ['5.000 a 10.000', '5000-10000'],
  ['10.000 a 25.000', '10000-25000'],
  ['25.000 a 50.000', '25000-50000'],
  ['Mais de 50.000', '50000-infinity'],
];

const operationLabels = {
  cobrancas: {
    title: 'Automatizar Cobranças',
    // label:
    //   'Quantos boletos e Pix sua empresa emite para recebimento em média por mês?',
  },
  transferencias: {
    title: 'Automatizar Transferências',
    // label:
    //   'Quantas transferências via Pix, DOC, TED e TEF sua empresa realiza em média por mês?',
  },
  pagamentos: {
    title: 'Automatizar Pagamentos',
    // label:
    //   'Quantos pagamentos de boletos, concessionárias e impostos sua empresa realiza em média por mês?',
  },
  extratos: {
    title: 'Conciliar Extratos',
    // label:
    //   'Quantas movimentações totais (linhas no extrato) todas as contas bancárias possuem em média por mês?',
  },
};

const MAX_STEPS = ranges.length - 1;

const Transacoes = () => {
  const history = useHistory();
  const [cookies] = useCookies();

  const getInitialVolume = useCallback((operation) => {
    const savedValue = Session.getItem(operation);
    return savedValue
      ? ranges.findIndex((range) => range[1] === savedValue)
      : 0;
  }, []);

  const [volumeData, setVolumeData] = useState({
    // cobrancas: getInitialVolume('cobrancas'),
    // transferencias: getInitialVolume('transferencias'),
    // pagamentos: getInitialVolume('pagamentos'),
    // extratos: getInitialVolume('extratos'),
    todas: getInitialVolume('todas'),
  });

  const [selectedOperations, setSelectedOperations] = useState({
    cobrancas: Session.getItem('cobrancas'),
    transferencias: Session.getItem('transferencias'),
    pagamentos: Session.getItem('pagamentos'),
    extratos: Session.getItem('extratos'),
  });

  const [bankAccounts, setBankAccounts] = useState(
    Session.getItem('bankAccounts') || []
  );
  const [bankAccountsError, setBankAccountsError] = useState('');
  const [selectedBancos, setSelectedBancos] = useState(
    Session.getItem('bancos') || []
  );
  const [operationErrorMessage, setOperationErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const updateVolumeData = (operation, newIndex) => {
    setVolumeData((prev) => ({
      ...prev,
      [operation]: newIndex,
    }));
    Session.setItem(operation, ranges[newIndex][1]);
  };

  const handleBankAccountsChange = (e) => {
    const newBankAccounts = e.target.value;
    setBankAccounts(newBankAccounts);
    Session.setItem('bankAccounts', newBankAccounts);
    if (bankAccountsError && newBankAccounts) {
      setBankAccountsError('');
    }
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const validateForm = () => {
        let isValid = true;
        if (!Object.values(selectedOperations).some(Boolean)) {
          setOperationErrorMessage(
            'É necessário selecionar pelo menos uma operação.'
          );
          isValid = false;
        }

        if (!bankAccounts || bankAccounts.length === 0) {
          setBankAccountsError('Este campo é obrigatório.');
          isValid = false;
        }

        if (selectedBancos.length === 0) {
          setErrorMessage('É necessário selecionar pelo menos um banco.');
          isValid = false;
        }

        return isValid;
      };

      if (validateForm()) {
        Session.setItem('bancos', selectedBancos);

        const sendData = {
          // Dados da Empresa
          Nome: Session.getItem('firstName'),
          Sobrenome: Session.getItem('lastName'),
          DDD: Session.getItem('ddd'),
          Telefone: Session.getItem('phone'),
          Email: Session.getItem('email'),
          CNPJ: Session.getItem('cnpj'),
          Empresa: Session.getItem('company'),
          Cargo:
            Session.getItem('jobTitle') === 'Outro'
              ? Session.getItem('otherJobTitle')
              : Session.getItem('jobTitle'),
          // Caso
          Caso: Session.getItem('case'),
          UseCase: Session.getItem('useCase'),
          // Interesse
          Interesse: Session.getItem('goal'),
          OutroInteresse: Session.getItem('otherGoal'),
          // Transacoes
          TipoOperacoes: {
            cobrancas: Session.getItem('cobrancas'),
            transferencias: Session.getItem('transferencias'),
            pagamentos: Session.getItem('pagamentos'),
            extratos: Session.getItem('extratos'),
          },
          Volumes: {
            // Não estamos pegando os volumes por tipo de operação mais por isso
            // esse código abaixo está comentado, mas deixei ai para caso a gente precise
            //
            // cobrancas: Session.getItem('cobrancas'),
            // transferencias: Session.getItem('transferencias'),
            // pagamentos: Session.getItem('pagamentos'),
            // extratos: Session.getItem('extratos'),
            todas: Session.getItem('todas'),
          },
          NumeroContas: Session.getItem('bankAccounts'),
          Bancos: Session.getItem('bancos'),
          // Legado
          // OutroSegmento: Session.getItem('otherSegment'),
          // Segmento: Session.getItem('segment'),
          utm_source: cookies['utm_source'],
          utm_medium: cookies['utm_medium'],
          utm_campaign: cookies['utm_campaign'],
          utm_content: cookies['utm_content'],
          utm_term: cookies['utm_term'],
          gclid: cookies['gclid']
        };
        if (process.env.NODE_ENV === 'production') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'onboarding.finished',
          });
        } else {
          console.log('Would send dataLayer.push', {
            event: 'onboarding.finished',
          });
        }
        if (process.env.NODE_ENV === 'production' || true) {
          // Zapier: https://zapier.com/editor/221367200
          // Catch Hook: https://hooks.zapier.com/hooks/catch/509690/3w0gynm/
          fetch(
            'https://hooks.zapier.com/hooks/catch/509690/3w0gynm/',
            {
              method: 'POST',
              body: JSON.stringify(sendData),
            }
          ).then((response) => response.json());
          console.log('Submitted data to Zapier');
        } else {
          console.log('Would submit data to Zapier', {
            url: 'https://hooks.zapier.com/hooks/catch/509690/3w0gynm/',
            data: sendData
          });
        }
        if (Session.getItem('todas') === '1000') {
          history.push('/agradecimento');
        } else {
          history.push('/reuniao');
        };
      }
    },
    [selectedOperations, bankAccounts, selectedBancos, history, cookies]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleEnterPress = (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleSubmit(e);
        e.preventDefault();
        e.stopPropagation();
      }
    };

    document.addEventListener('keydown', handleEnterPress);
    return () => document.removeEventListener('keydown', handleEnterPress);
  }, [handleSubmit]);

  return (
    <>
      <div className="main-space">
        <PageHeader
          className="custom-padding-bottom"
          value="Fale um pouco mais sobre as transações que vocês realizam"
        />
        <form
          className="onboarding-form column"
          onSubmit={handleSubmit}
          data-testid="form-transactions"
        >

          <h3>O que você deseja resolver com a Kobana?</h3>

          {Object.entries(operationLabels).map(([key, { title }]) => (
            <OperationSection
              key={key}
              title={title}
              operation={key}
              selected={selectedOperations[key]}
              onCheckboxChange={() => {
                setSelectedOperations((prev) => ({
                  ...prev,
                  [key]: !prev[key],
                }));
                setOperationErrorMessage('');
              }}
            />
          ))}
          {operationErrorMessage && (
            <div className="transaction-error">{operationErrorMessage}</div>
          )}

          <h3>Quantas operações financeiras sua empresa realiza por mês?</h3>
          <p>Somatório de todas as transferências por Pix, TED, pagamentos de boletos, impostos, guias, cobranças via boleto e pix, de acordo com a seleção acima.</p>

          <div className="range-labels">
            <div className="custom-flex">
              <input
                className="input-range"
                type="range"
                id={`todasVolume`}
                name={`todasVolume`}
                value={volumeData['todas']}
                min={0}
                max={MAX_STEPS}
                step={1}
                onChange={(e) => updateVolumeData('todas', parseInt(e.target.value))}
              />
              <span>{ranges[volumeData['todas']][0]}</span>
            </div>
          </div>

          <BankAccountsSection
            bankAccounts={bankAccounts}
            bankAccountsError={bankAccountsError}
            selectedBancos={selectedBancos}
            errorMessage={errorMessage}
            onBankAccountsChange={handleBankAccountsChange}
            onBancosChange={setSelectedBancos}
            setErrorMessage={setErrorMessage}
          />
        </form>
      </div>
      <div className="bottom-actions internal-space custom-bottom-action">
        <PrimaryButton onClick={handleSubmit} />
        <Button value="Voltar" onClick={() => history.goBack()} />
      </div>
    </>
  );
};

const OperationSection = ({
  title,
  operation,
  selected,
  onCheckboxChange,
}) => {
  const handleCheckboxChange = () => {
    let isSelected = !selected;
    Session.setItem(operation, isSelected);
    onCheckboxChange();
  };

  return (
    <div className="container-range" data-testid={`container-${operation}`}>
      <Checkbox
        title={title}
        name={operation}
        checked={selected}
        onChange={handleCheckboxChange}
        data-testid={`checkbox-${operation}`}
      />
      {/* <div className="range-labels">
        <div
          className="custom-flex"
          style={selected ? { display: 'flex' } : { display: 'none' }}
        >
          <input
            className="input-range"
            type="range"
            id={`${operation}Volume`}
            name={`${operation}Volume`}
            value={volume}
            min={0}
            max={MAX_STEPS}
            step={1}
            onChange={(e) => onRangeChange(parseInt(e.target.value))}
            disabled={!selected}
          />
          <span>{ranges[volume][0]}</span>
        </div>
      </div> */}
    </div>
  );
};

const BankAccountsSection = ({
  bankAccounts,
  bankAccountsError,
  selectedBancos,
  errorMessage,
  onBankAccountsChange,
  onBancosChange,
  setErrorMessage,
}) => (
  <>
    <div className="input-mini bank-list">
      <h3>Quantas contas bancárias sua empresa possui?</h3>
      <input
        type="number"
        name="accounts"
        id="accounts"
        value={bankAccounts}
        onChange={onBankAccountsChange}
        className="input-pernonal-data"
      />
      {bankAccountsError && (
        <div className="transaction-error">{bankAccountsError}</div>
      )}
    </div>
    <div className="bank-tag">
      <h3>Em quais bancos?</h3>
      <TagInput
        selectedBanks={selectedBancos}
        setSelectedBanks={onBancosChange}
        setErrorMessage={setErrorMessage}
      />
      {errorMessage && <div className="transaction-error">{errorMessage}</div>}
    </div>
  </>
);

export default Transacoes;
