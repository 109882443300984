import React from 'react';
import Session from '../helpers/Session';

const Sucesso = (props) => {
  return (
    <div className="internal-space final-page">
      <h1>Parabéns, sua reunião com a Kobana está confirmada!</h1>
      <p>{Session.getItem('firstName')},</p>
      <p>Obrigado pelo seu interesse na Kobana.</p>
      <p>Sua reunião foi agendada e estamos ansiosos para conversar com você na data marcada. Caso precise ajustar o horário ou tenha alguma dúvida, é só nos chamar pelo <a href="https://l.kobana.com.br/whatsapp">WhatsApp</a>.</p>
      <p>● A equipe Kobana</p>
    </div>
  )
}

export default Sucesso;
