import React, { useEffect, useCallback } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import Button from '../components/Button';
import Calendly from '../components/Calendly';
import { useCookies } from 'react-cookie';

const Reuniao = (props) => {

  const handleSubmit = useCallback(
    (e) => {
      if (e) e.preventDefault();
      props.history.push('/sucesso');
    },
    [props.history]
  );
  
  const handleBackClick = (_e) => {
    props.history.push('/transacoes');
  };

  const [cookies] = useCookies();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleEnter = (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleSubmit(e);
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [cookies, handleSubmit]);

  return (
    <div>
      <form
        id="form"
        className="onboarding-form"
        data-parsley-validate
        onSubmit={handleSubmit}
        data-parsley-errors-messages-disabled
      >
        <Calendly />
        <button type="submit" className="display-none" />
      </form>
      <div className="btn-continue-position custom-calendly">
        <PrimaryButton onClick={handleSubmit} />
        <Button value="Voltar" onClick={handleBackClick} />
      </div>
    </div>
  );
};

export default Reuniao;
